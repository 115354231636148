


<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />

    <v-layout wrap pt-7 pr-1 justify-end>
      <v-flex xs2 pb-5>
        <v-btn
          dark
          class="no-uppercase"
          width="160px"
          color="#13736f"
          @click="dialog2 = true"
          ><span
            style="
              font-family: mainfont;
              font-size: 15px;
              color: white;
              letter-spacing: 1px;
              cursor: pointer;
            "
            >Add
          </span>
          <v-icon dark size="15px"> mdi-plus </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap px-5 justify-center
      ><v-flex
        xs12
        lg4
        pa-2
        v-for="(item, i) in list"
        :key="i"
        class="mainfont"
        style="color: white; font-size: 15px; font-weight: lighter"
      >
        <v-card height="100%">
          <v-layout wrap justify-center>
            <v-flex xs12 pl-3 pr-3 pt-3 align-self-center>
              <v-img
                height="100%"
               contain
                @click="$router.push('/BlogDetailedpage?id=' + item._id)"
                :src="mediaURLnew + item.photos[0]"
              >
              </v-img>
            </v-flex>

            <v-flex pl-3 pt-2 xs12 align-self-center>
              <span
                class="mainfont"
                style="color: #757575; font-size: 12px; font-weight: lighter"
                >{{ formatDate(item.create_date) }}</span
              >
            </v-flex>
            <v-flex pl-3 pb-2 pt-1 xs12 align-self-center>
              <span
                class="mainfont"
                style="color: black; font-size: 18px; font-weight: bold"
                >{{ item.title }}</span
              >
            </v-flex>

            <v-flex px-3 xs12 align-self-center class="justified-text">
              <span

              v-html="item.content.split(' ').splice(0, 300).join(' ') + (item.content.split(' ').length > 300 ? '...' : '')"
                class="mainfont"
                style="color: #757575; font-size: 12px; font-weight: 400px"
                ></span
              >
            </v-flex>
            <v-flex xs12 pb-2 align-self-center>
              <v-layout wrap fill-height justify-end>
                <v-flex xs2  text-left>
                  <v-btn
                    class="mx-2"
                    fab
                    small
                    elevation="0"
                    color="#EFFFFA"
                    @click="(editdailog = true), (curid = item)"
                  >
                    <v-icon color="black" size="130%" dark> mdi-pencil </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs2 text-left >
                  <v-btn
                    class="mx-2"
                    small
                    fab
                    elevation="0"
                    color="#EFFFFA"
                    @click="(deletedialog = true), (curid = item._id)"
                  >
                    <v-icon color="red" dark size="130%"> mdi-delete </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card >
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Are you sure you want to delete this Blog?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="true"
      persistent
      v-model="dialog2"
      max-width="950px"

    >
      <v-card >
        <v-layout wrap justify-center>
          <v-flex
            ><v-layout wrap justify-center pa-4>
              <v-flex xs12 text-right>
                <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                  mdi-close
                </v-icon>
              </v-flex>

              <v-flex xs12> </v-flex>
              <v-flex xs10 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px;font-weight:bold"
                  >Title</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="title"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs10 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px;font-weight:bold"
                  >Content</span
                >
                <v-form @submit.prevent="validateInput">
                  <vue-editor v-model="content"></vue-editor>
                </v-form>
              </v-flex>
              <v-flex pt-3 class="mainfont" xs10 style="color: black; font-size: 16px;font-weight:bold">
                <Images
                  @stepper="winStepper"
                  :height="'700'"
                  :width="'1300'"
                  :heading="'Upload Images'"
                />
              </v-flex>

              <v-flex xs10 text-right  pt-4>
                <v-btn dark width="100px" color="#13736f" @click="add()">
                  <span style="color: white; font-size: 14px;" class="mainfont" > Add </span>
                  <v-icon size="15px" color="white">mdi-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="editdailog"
      max-width="950px"

    >
      <v-card >
        <v-layout wrap justify-center pa-3>
          <v-flex
            ><v-layout wrap justify-center >

              <v-flex xs12 text-right>
                  <v-icon
                    @click="editdailog = false"
                    color="red"
                    size="150%"
                    dark
                  >
                    mdi-close
                  </v-icon>
                </v-flex>
            
              <v-flex xs10 pt-4 class="mainfont">
                <span class="mainfont" style="color: black; font-size: 16px;font-weight:bold"
                  >Title</span
                >
                <v-form @submit.prevent="validateInput">
                  <v-text-field
                    color="black"
                    outlined
                    background-color="white"
                    dense
                    type="text"
                    v-model="curid.title"
                    hide-details
                  >
                  </v-text-field>
                </v-form>
              </v-flex>

              <v-flex pt-2 xs10 class="mainfont">
                <span class="mainfont"
                style="color: black; font-size: 16px;font-weight:bold"
                  >Content</span
                >

                <vue-editor   v-model="curid.content"></vue-editor>
              
              </v-flex>

              <v-flex xs10 pa-3 v-for="(item, i) in curid.photos" :key="i">
                <v-img :src="mediaURLnew + item"></v-img>
              </v-flex>
              <v-flex xs10 pa-2 class="mainfont" style="color: black; font-size: 16px;font-weight:bold">
                <Images
                  @stepper="winStepper"
                  :height="'700'"
                  :width="'1300'"
                  :heading="'Upload Images'"
                />
              </v-flex>
              <v-flex xs10 pr-2 text-right >
                  <v-btn dark width="100px" color="#13736f" @click="edit()">
                    <span class="mainfont"  style="color: white; font-size: 14px;"> Edit </span>
                    <v-icon size="15px" color="white">mdi-pencil</v-icon>
                  </v-btn>
                </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout pt-9 pb-6 wrap justify-center>
              <v-flex align-self-center>
                <div>
                  <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                    v-model="currentPage" color="#13736f"></v-pagination>
                </div>
              </v-flex>
            </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import Images from "@/components/Common/multipleImages";
export default {
  props: ["id"],

  components: {
    VueEditor,
    Images,
  },
  data() {
    return {
      title: null,
      content: [],
      photos: [],
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dialog2: false,
      deletedialog: false,

      imageArray: [],
      imageError: "",
      editdailog: false,
      list: [],
      imageCoverError: "",
      formData: new FormData(),
      formData2: new FormData(),

      selectedFiles: [],
      curid: [],
      image: null,
      coverImageArray: [],
      appLoading: false,
      currentPage: 1,
      pages: 0,

      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
    };
  },
  mounted() {
    this.getlist();
  },
    watch: {
    currentPage() {
      this.getlist();
    },
 
  },
  methods: {
    deleteItem() {
      axios({
        url: "/admin/blog/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    winStepper(window_data) {
      if (window_data.type == "AddPackage") {
        this.slotAddition = window_data.slotAddition;
        this.packageRate = window_data.response;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "topimage") {
        this.topimageFile = window_data.selectedFiles;
      } else if (window_data.type == "product top image") {
        this.producttopimagefile = window_data.selectedFiles;
      } else if (window_data.type == "product right image") {
        this.productrightimagefile = window_data.selectedFiles;
      }
    },
    validateInput() {
      if (!this.home.top.title) {
        this.msg = "Please Provide Top title";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      console.log("imgfiles=", this.imageFiles);
      this.formData.append("title", this.title);
      this.formData.append("content", this.content);
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }

      axios({
        method: "post",
        url: "/admin/blog/add",
        data: this.formData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    edit() {
      console.log("imgfiles=", this.imageFiles);
      this.formData2.append("title", this.curid.title);
      this.formData2.append("id", this.curid._id);
      this.formData2.append("content", this.curid.content);
      for (var i = 0; i < this.imageFiles.length; i++) {
        this.formData2.append(
          "photos",
          this.imageFiles[i],
          this.imageFiles[i].name
        );
      }
      axios({
        method: "post",
        url: "/admin/blog/edit",
        data: this.formData2,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("programme", item);
      axios({
        method: "POST",
        url: "/programme/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showSnackBar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadImage() {
      this.appLoading = true;
      this.formDataCover.append("image", this.topimage);
      axios({
        method: "POST",
        url: "/home/image",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.isProgramAdded = true;
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getlist() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blog/list",
        headers: {
          token: localStorage.getItem("token"),
        },
           params: {
          page: this.currentPage,
          limit: 10,
         
        },
      })
        .then((response) => {
          this.list = response.data.data;
            this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}

.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
.flex-red {
  background-color: #13736f;
}
.justified-text {
  text-align: justify;
}
.flex-g {
  background-color: #13736f;
}
.bg8 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.no-uppercase {
  text-transform: none !important;
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
.cus {
  background-color: #13736f;
}
</style>